<template>
  <v-container>
    <!-- <Enroll
      v-if="!loadingEnrolled && !enrolled"
      @show-card-info="showCardInfo"
      class="mb-5"
    /> -->
    <router-view :cards="cards" @showCardInfo="showCardInfo"></router-view>
    <CardInfo
      ref="cardInfo"
      v-model="card"
      :dialog="dialog"
      :submitting="submitting"
      @close="dialog = !dialog"
      @submit="addorUpdateCard"
    />
  </v-container>
</template>

<script>
import { api } from '@/sharedPlugin';

import CardInfo from '@/components/CardInfo.vue';
import { mapMutations } from 'vuex';
const Swal = require('sweetalert2');
export default {
  name: 'CryoIndex',
  components: {
    CardInfo
  },
  data() {
    return {
      loadingEnrolled: false,
      submitting: false,
      cards: [],
      dialog: false,
      card: {
        profileid: 0,
        account: '',
        cvv: '',
        expiry: '',
        zipcode: ''
      }
    };
  },
  computed: {
    enrolled() {
      return this?.cards?.length > 0;
    }
  },
  methods: {
    ...mapMutations(['showSnackbar']),
    isEnrolled() {
      this.cards = [];
      this.loadingEnrolled = true;
      api.CardConnect.getCardConnectProfile()
        .then(async (res) => {
          if (res.length > 0) {
            const cardProfiles = [...res];
            cardProfiles.forEach((cardProfile) => {
              this.cards.push({
                ...cardProfile,
                expiry: cardProfile.expires,
                account:
                  cardProfile.token.substr(1, 2) +
                  'XX XXXX XXXX ' +
                  cardProfile.last_four,
                cardType: cardProfile.description,
                lastfour: cardProfile.last_four
              });
            });
          }
        })
        .catch((err) => alert(err))
        .finally(() => (this.loadingEnrolled = false));
    },
    addorUpdateCard() {
      let isDefault = this.cards && this.cards.length > 0 ? 0 : 1;
      this.card.is_default = isDefault;
      this.submitting = true;
      const apiCall =
        this.card.profileid === 0
          ? api.CardConnect.addCard
          : api.CardConnect.updateCard;

      let isCard =
        this.cards.filter((card) => card.last_four === this.card.lastfour)
          .length > 0
          ? true
          : false;

      if (isCard) {
        this.submitting = false;

        this.showSnackbar({
          message: 'This card is already registered!',
          color: 'error'
        });
        return;
      }

      return apiCall(this.card)
        .then((res) => {
          Swal.fire({
            title: 'Payment Method Update Successful!',
            text: 'Your credit card will be charged for upcoming storage payments. If you have any outstanding balances, please review your account.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ok'
          }).then((result) => {});

          this.$refs.cardInfo.cleanIput();
          this.clearCard();
          this.isEnrolled();
        })
        .catch(() => {
          Swal.fire({
            title: 'Payment Method',
            text: 'Error submitting card',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ok'
          }).then((result) => {});
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    showCardInfo(card) {
      if (card !== undefined) {
        this.card = { ...card };
      } else {
        this.clearCard();
      }
      this.$refs.cardInfo.show();
    },
    clearCard() {
      this.card = {
        profileid: 0,
        account: '',
        cvv: '',
        expiry: '',
        zipcode: ''
      };
    }
  },
  created() {
    this.isEnrolled();
  }
};
</script>

<style scoped>
.theme--primary.v-card {
  color: '#1F6070';
}
</style>
